import React from "react";
import gsap from "gsap";
import Sliver from "./Sliver";

import { SLIVER_VIEW } from "./States";

class SliverView extends React.Component {
  constructor(props) {
    super(props);
    this.selectedSliver = props.onSliverSelect;
    this.imagesReady = props.imagesReady;
    this.onSliverHideFinish = props.onSliverHideFinish;

    this.state = {
      loadedNum: 0,
      imagesReady: false,
    };
  }

  componentDidMount() {
    this.sliverTL = gsap.timeline({
      paused: true,
      onComplete: () => {
        gsap.to(".solid-fill", { duration: 2.0, opacity: 0, delay: 0.25 });
      },
    });
    this.sliverTL.addLabel("curtains-closed");
    this.sliverTL.to(".curtain", {
      duration: 0.75,
      left: "100%",
      stagger: 0.1,
    });
    this.sliverTL.addLabel("curtains-opened");
  }

  checkMatchView() {
    if (this.props.viewState === SLIVER_VIEW) return true;
    return false;
  }

  componentDidUpdate() {
    // console.log("state", this.props.viewState);
    if (this.checkMatchView()) {
      this.showSlivers();
    } else {
      if (this.props.initView) return;
      this.hideSlivers();
    }
  }

  imageLoadedCallback() {
    this.setState(
      {
        loadedNum: this.state.loadedNum + 1,
      },
      () => {
        if (this.state.loadedNum === this.props.data.length) {
          this.setState({ imagesReady: true });
          this.imagesReady();
        }
      }
    );
  }

  selectedSliver(index) {
    this.selectedSliver(index);
  }

  showSlivers() {
    this.sliverTL.tweenTo("curtains-opened");
  }

  hideSlivers() {
    console.log("hiding slivers");
    this.sliverTL.tweenTo("curtains-closed", {
      onComplete: () => this.onSliverHideFinish(),
    });
  }

  render() {
    return (
      <div
        className="sliver-container"
        style={{ pointerEvents: this.checkMatchView() ? "all" : "none" }}
      >
        {this.props.data.map((ele, i) => {
          return (
            <Sliver
              sliverData={ele}
              key={i}
              index={i}
              tl={this.sliverTL}
              selectHandler={this.selectedSliver}
              loadedCallback={this.imageLoadedCallback.bind(this)}
            />
          );
        })}
      </div>
    );
  }
}

export default SliverView;
