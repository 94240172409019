import React from 'react';
import './style.scss';

const CustomArrowRight = () => (
  <svg version="1.1" className="arrow-right" x="0px" y="0px"
    viewBox="0 0 122.1 97.8" style={{enableBackground:'new 0 0 122.1 97.8'}}>
  <path d="M122.1,48.9L81.1,97.8H44.1l41.1-48.9L44.1,0h36.9L122.1,48.9z M36.9,0H0l41.1,48.9L0,97.8h36.9L78,48.9L36.9,0z"/>
  </svg>

)
export default CustomArrowRight;

