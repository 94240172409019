import React from 'react';
import {TranslatableText} from '../../providers/LanguageProvider';
import { Fade } from "react-awesome-reveal";
import './style.scss';

class SectionActions extends React.Component {

  render() {
    return (
      <section className="actions">
      <Fade triggerOnce direction="left" delay={500}>
        <h2>

        <span class="glitch" data-text="Actions">
        <TranslatableText
              dictionary={{
                en: "Actions",
              }}
          ></TranslatableText>
          </span>
          <span class="glitch" data-text="Des Actions">
          <TranslatableText
              dictionary={{
                fr: "Des Actions",
              }}
          ></TranslatableText>
          </span>

          <br />

          <span class="glitch2" data-text="to take">
          <TranslatableText
              dictionary={{
                en: "to take",
              }}
          ></TranslatableText>
          </span>

          <span class="glitch2" data-text="À POSER">
          <TranslatableText
              dictionary={{
                fr: "À POSER",
              }}
          ></TranslatableText>
          </span>
          <br />
          <span class="glitch3" data-text="towards healing">
          <TranslatableText
              dictionary={{
                en: "towards healing",
      
              }}
          ></TranslatableText>
          </span>
          <span class="glitch3" data-text="POUR LA GUÉRISON">
          <TranslatableText
              dictionary={{
      
                fr: "POUR LA GUÉRISON",
              }}
          ></TranslatableText>
          </span>
        </h2>
        </Fade>
        <div className="steps-container">
        <Fade cascade triggerOnce className="step-block-animation" delay={700}>

          <div className="step-one step-block">
            <p>
              <TranslatableText
                dictionary={{
                  en: "Demonstrate a positive and respectful attitude towards all orientations, gender identities and gender expressions.",
                  fr: "Démontrer une attitude positive et respectueuse envers la diversité des orientations sexuelles, des identités de genre et des expressions de genre.",
                }}
            ></TranslatableText>
            </p>
            <span>1.</span>
           
          </div>
          <div className="step-two step-block">
            <p>
              <TranslatableText
                dictionary={{
                  en: "Recognize, confront and denounce violence and discrimination against LGBTQ+ people",
                  fr: "Reconnaître, confronter et dénoncer la violence et la discrimination envers les personnes LGBTQ+.",
                }}
              ></TranslatableText>
            </p>
            <span>2.</span>
          </div>
          <div className="step-three step-block">
            <p>
              <TranslatableText
                dictionary={{
                  en: "Ensure the confidentiality of information received, i.e. not mentioning a person's sexual orientation or gender identity without their consent (this includes respecting the first name and pronouns that a person uses)",
                  fr: "Assurer la confidentialité des informations reçues, c’est-à-dire, ne pas mentionner l’orientation sexuelle ou l’identité de genre d’une personne sans son consentement (cela inclut de respecter le prénom et les pronoms qu’une personne utilise).",
                }}
              ></TranslatableText>
            </p>
            <span>3.</span>
          </div>
          <div className="step-four step-block">
            <p>
              <TranslatableText
                dictionary={{
                  en: "Offer to accompany your friends to Pride events or other demonstrations for LGBTQ+ rights.",
                  fr: "Proposer à vos ami.e.s de les accompagner lors d’évènements de la Fierté ou autres manifestations pour les droits LGBTQ+.",
                }}
              ></TranslatableText>
            </p>
            <span>4.</span>
          </div>
        </Fade>

        </div>
      </section>
    )
  }
}
export default SectionActions;