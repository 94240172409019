import React from 'react';
import {TranslatableText} from '../../providers/LanguageProvider';
import { Fade } from "react-awesome-reveal";
import './style.scss';

import FacebookIcon from '../../assets/svg/FacebookIcon/FacebookIcon';
import TwitterIcon from '../../assets/svg/TwitterIcon/TwitterIcon';
import InstagramIcon from '../../assets/svg/InstagramIcon/InstagramIcon';

//collab effort
import SponsorLogo1 from '../../assets/images/sponsor-emergence.png';


// official presenter
import SponsorLogo2Fr from '../../assets/images/sponsor-banq-nationale.png';
import SponsorLogo2En from '../../assets/images/sponsor-bank-national.png';

// partner 
import SponsorLogo16 from '../../assets/images/sponsor-quebec.png';

// sponsor
import SponsorLogo3 from '../../assets/images/sponsor-montreal.png';
import SponsorLogo12 from '../../assets/images/sponsor-riotinto.png';
import SponsorLogo18 from '../../assets/images/sponsor-post-media.png';
import SponsorLogo19En from '../../assets/images/sponsor-quebecor-en.png';
import SponsorLogo19Fr from '../../assets/images/sponsor-quebecor-fr.png';

// media partner
import SponsorLogo13 from '../../assets/images/sponsor-fugues.png';
import SponsorLogo15 from '../../assets/images/sponsor-lgbt.png';
import SponsorLogo14 from '../../assets/images/sponsor-sauvage.png';

// help resource
import SponsorLogo9 from '../../assets/images/sponsor-atq.png';
import SponsorLogo8 from '../../assets/images/sponsor-stop-homophobia.png';
import SponsorLogo11 from '../../assets/images/sponsor-interligne.png';
import SponsorLogo6 from '../../assets/images/sponsor-fierte.png';
import SponsorLogo4 from '../../assets/images/sponsor-dignity.png';
import SponsorLogo10 from '../../assets/images/sponsor-collectif.png';
import SponsorLogo5 from '../../assets/images/sponsor-egale.png';
import SponsorLogo7 from '../../assets/images/sponsor-idaho.png';
import SponsorLogo17 from '../../assets/images/sponsor-purge.png';
//import SponsorLogo17En from '../../assets/images/sponsor-purge-en.png';
//import SponsorLogo17Fr from '../../assets/images/sponsor-purge-fr.png';




class Footer extends React.Component {

  render() {
    return (
      <>
        <section className="sponsors">
          <div className="container">
            <div className="content">
              <div className="sponsor-list collab">
              <p>
              <TranslatableText
                dictionary={{
                  en: "An initiave of",
                  fr: "Initiative de"
                }}
              ></TranslatableText>
              </p>
              <img src={SponsorLogo1} alt="" />
            </div>
            <div className="sponsor-list official-presenter">
              <p>
                <TranslatableText
                dictionary={{
                  en: "Official Presenter",
                  fr: "Présentateur officiel"
                }}
              ></TranslatableText>
              </p>
              {this.props.lang === 'en' ? 
                <img src={SponsorLogo2En} alt="" />
                : 
                <img src={SponsorLogo2Fr} alt="" />
              }
            </div>
            <div className="sponsor-list partner-sponsor">
              <p>
                <TranslatableText
                dictionary={{
                  en: "Partner",
                  fr: "Partenaire"
                }}
              ></TranslatableText>
              </p>
              <img src={SponsorLogo16} alt="" />
              <p>
                <TranslatableText
                dictionary={{
                  en: "Sponsors",
                  fr: "Commanditaires"
                }}
              ></TranslatableText>
              </p>
              <img src={SponsorLogo3} alt="" />


            </div>
            <div className="sponsor-list sponsor-pt-2">
              <p>&nbsp;</p>
              <img src={SponsorLogo12} alt="" />
              <img src={SponsorLogo18} alt="" />
              {this.props.lang === 'en' ? 
                <img src={SponsorLogo19En} alt="" />
                : 
                <img src={SponsorLogo19Fr} alt="" />
              }
            </div>
            <div className="sponsor-list media-partner">
              <p>
                <TranslatableText
                dictionary={{
                  en: "Media Partners",
                  fr: "Partenaires médias"
                }}
              ></TranslatableText>
              </p>
              <img src={SponsorLogo13} alt="" />
              <img src={SponsorLogo15} alt="" />
              <img src={SponsorLogo14} alt="" />
            </div>
            <div className="sponsor-list help-resource">
              <p>
                <TranslatableText
                dictionary={{
                  en: "Help Resources",
                  fr: "Ressources d’aide"
                }}
              ></TranslatableText>
              </p>
              <img src={SponsorLogo9} alt="" />
              <img src={SponsorLogo8} alt="" />
              <img src={SponsorLogo11} alt="" /> 
              <img src={SponsorLogo6} alt="" />
              <img src={SponsorLogo4} alt="" />
              <img src={SponsorLogo10} alt="" />
              <img src={SponsorLogo5} alt="" />
              <img src={SponsorLogo7} alt="" />
              <img src={SponsorLogo17} alt="" />
       
            </div>
          </div>
        </div>
         
        </section>
        <footer>
        <Fade triggerOnce direction="up" delay={200} style={{display:"inline-block"}}>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/InternationalMay17"><FacebookIcon iconColor="#000"/></a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/may17mai"><TwitterIcon iconColor="#000" /></a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/may17mai/"><InstagramIcon iconColor="#000" /></a>
        </Fade>
        <Fade triggerOnce direction="up" delay={200}>
          <p>
            Fondation Émergence<br />
            C.P. 55510<br />
            <TranslatableText
              dictionary={{
                en: "Maisonneuve Center",
                fr: "Centre Maisonneuve",
              }}
            ></TranslatableText>
            <br />
            Montreal (Quebec) H1W 0A1<br /><br />
            
            <TranslatableText
              dictionary={{
                en: "Phone: ",
                fr: "Téléphone : ",
              }}
            ></TranslatableText>
            
            438 384-1058<br />
            <TranslatableText
              dictionary={{
                en: "Email:",
                fr: "Courriel : ",
              }}
            ></TranslatableText>
            
            {this.props.lang === 'en' ? 
              <a href="mailto:mail@fondationemergence.org">mail@fondationemergence.org</a>
              : 
              <a href="mailto:courrier@fondationemergence.org">courrier@fondationemergence.org</a>
            }
          </p>
        </Fade>
        
        </footer>
      </>
    )
  }
}
export default Footer;