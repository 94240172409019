import React from "react";

class BGImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.loadedCallback = props.loadedCallback;
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(preState) {
    if (preState.src !== this.props.src) this.loadImage();
  }

  async loadImage() {
    await fetch(this.props.src);
    this.loadedCallback();
  }

  render() {
    return (
      <div
        className={this.props.className ? this.props.className : "img-block"}
        style={{ backgroundImage: `url(${this.props.src})` }}
      >
        <div
          className="solid-fill"
          style={{ backgroundColor: this.props.solidFill }}
        ></div>
      </div>
    );
  }
}

export default BGImageLoader;
