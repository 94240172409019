import React from 'react';
import './style.scss';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Wrapper = ({lang, children, pageCurrent}) => {

return (

  <div className={`wrapper ${pageCurrent}`}>
    <div className="backdrop"></div>
    <Header lang={lang} />

      {children}
 
    <Footer lang={lang} />
  </div>
)
}
export default Wrapper;