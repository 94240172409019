import React, { createRef } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import { scroller, Element } from "react-scroll";

import "./style.scss";
import VisibilitySensor from "react-visibility-sensor";

import SliverView from "./parts/SliverView";
import TestimonyView from "./parts/TestimonyView";
import { SliverDataEnglish, SliverDataFrench } from "./models/SliverData";

import {
  SLIVER_VIEW,
  TESTIMONY_VIEW,
  SLIVER_TO_TESTIMONY_VIEW,
  TESTIMONY_TO_SLIVER_VIEW,
  INIT_VIEW,
} from "./parts/States";

// gsap.registerPlugin(ScrollToPlugin);

class Testimonies extends React.Component {
  constructor(props) {
    super(props);
    this.visRef = createRef();
    this.state = {
      selectedIndex: -1,
      view: INIT_VIEW,
      initView: true,
      imagesReady: false,
      glitchingDisabled: true,
    };
  }

  componentDidMount() {}

  sliverSelect(index) {
    console.log(index, " selected");
    this.setState({
      selectedIndex: index,
      view: SLIVER_TO_TESTIMONY_VIEW,
      glitchingDisabled: false,
    });
    this.scrollToSection();
    setTimeout(() => {
      this.setState({
        glitchingDisabled: true,
      });
    }, 5000);
  }

  testimonyExit() {
    this.setState({
      view: TESTIMONY_TO_SLIVER_VIEW,
      glitchingDisabled: true,
    });
    this.scrollToSection();
  }

  sliverHideFinish() {
    if (this.state.view === SLIVER_TO_TESTIMONY_VIEW) {
      console.log("slivers are finished hiding");
      this.setState({
        view: TESTIMONY_VIEW,
      });
    }
  }

  testimonyHideFinish() {
    console.log("testimony is now finished hiding");
    if (this.state.view === TESTIMONY_TO_SLIVER_VIEW) {
      console.log("slivers are finished hiding");
      this.setState({
        view: SLIVER_VIEW,
      });
    }
  }

  scrollToSection() {
    const yOffset = document
      .querySelector(".nav-wrapper")
      .getBoundingClientRect().height;
    scroller.scrollTo("TestimoniesScrollpoint", {
      offset: -yOffset - 10,
    });
  }

  handleVisibleChange(visTest) {
    if (visTest && this.state.initView && this.state.imagesReady) {
      this.setState({
        initView: false,
        view: SLIVER_VIEW,
      });
    }
  }

  manualVisibleCheck() {
    const visTest = this.visRef.current.state.isVisible;
    this.handleVisibleChange(visTest);
  }

  imagesReady() {
    this.setState(
      {
        imagesReady: true,
      },
      () => {
        this.manualVisibleCheck();
      }
    );
  }

  getSliverLanguageData(lang) {
    // TODO: update how language switching is handled
    // You'll need to use the LanguageConsumer in order to get which language
    // is currently in use
    if (this.props.lang === "en") {
      return SliverDataEnglish;
    } else {
      return SliverDataFrench;
    }
  }

  render() {
    return (
      <>
        <Element name="TestimoniesScrollpoint" />
        <div className="section-testimonies">
          {/* <LanguageConsumer> */}
          <VisibilitySensor
            ref={this.visRef}
            onChange={this.handleVisibleChange.bind(this)}
            minTopValue={200}
            partialVisibility={true}
          >
            <SliverView
              data={this.getSliverLanguageData(this)}
              imagesReady={this.imagesReady.bind(this)}
              onSliverSelect={this.sliverSelect.bind(this)}
              viewState={this.state.view}
              initView={this.state.initView}
              onSliverHideFinish={this.sliverHideFinish.bind(this)}
            />
          </VisibilitySensor>
          <TestimonyView
            viewState={this.state.view}
            glitchingDisabled={this.state.glitchingDisabled}
            data={this.getSliverLanguageData(this)}
            selectedIndex={this.state.selectedIndex}
            onSelectBack={this.testimonyExit.bind(this)}
            onTestimonyHideFinish={this.testimonyHideFinish.bind(this)}
          />
          {/* </LanguageConsumer> */}

          <div
            className="loading-icon"
            style={{ opacity: this.state.imagesReady ? 0 : 1 }}
          >
            <ScaleLoader color="#ffffff" />
          </div>
        </div>
      </>
    );
  }
}
export default Testimonies;
