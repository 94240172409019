//import { render } from '@testing-library/react';
import React from 'react';
import Navbar from '../Navbar/Navbar';


class Header extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Navbar lang={this.props.lang} />
      </React.Fragment>
    )
  }
}
export default Header;