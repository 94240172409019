import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../assets/scss/styles.scss";

import LanguageProvider from "../providers/LanguageProvider";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import Home from "../pages/Home/Home";

class App extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };
  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      ageCookie: cookies.get("ageGateTest") ? true : false,
    };
  }
  render() {
    return (
      <LanguageProvider>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/:lang" component={Home} />
            <Route exact path="/:lang/home" component={Home} />
          </Switch>
        </Router>
      </LanguageProvider>
    );
  }
}
export default withCookies(App);
