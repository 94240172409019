import React, { createRef } from "react";
import gsap from "gsap";
// import PerfectScrollbar from "react-perfect-scrollbar";

import BGImageLoader from "./BGImageLoader";
import GlitchEffect from "react-glitch-effect/core/GlitchClip";

import { TESTIMONY_VIEW } from "./States";
import CloseBtn from "../images/close-btn.png";

class TesimonyView extends React.Component {
  constructor(props) {
    super(props);
    this.selectedBack = props.onSelectBack;
    this.onTestimonyHideFinish = props.onTestimonyHideFinish;
    this.scrollRef = createRef();
  }

  componentDidMount() {
    this.testimonyTL = gsap.timeline({ paused: true });
    this.testimonyTL.addLabel("curtains-closed");
    this.testimonyTL.add([
      gsap.from(
        [
          ".image-2-pos",
          ".image-1-pos",
          ".profile-pos",
          ".testimony-container-right",
        ],
        1.0,
        {
          y: 40,
          opacity: 0,
          stagger: 0.2,
        }
      ),
      gsap.from(".covertitle", 1.0, { opacity: 0 }),
    ]);
    this.testimonyTL.addLabel("curtains-opended");
    this.floatTimelines = {};
    this.createFloatAnimations(this.floatTimelines);
  }

  createFloatAnimations(floatTLObject) {
    floatTLObject.image1X = this.createFloat(".image-1-float", 10, 4, "x");
    floatTLObject.image1Y = this.createFloat(".image-1-float", 12, 3, "y");
    floatTLObject.image2X = this.createFloat(".image-2-float", 11, 4, "x");
    floatTLObject.image2Y = this.createFloat(".image-2-float", 13, 3, "y");
    floatTLObject.imageProfileX = this.createFloat(
      ".profile-float",
      15,
      5,
      "x"
    );
    floatTLObject.imageProfileY = this.createFloat(
      ".profile-float",
      11,
      4,
      "y"
    );
  }

  createFloat(selector, duration, distance, direction) {
    const floatTL = gsap.timeline({ repeat: -1 });
    floatTL.fromTo(
      selector,
      { [direction]: -distance },
      { [direction]: distance, duration, ease: "sine.inOut" }
    );
    floatTL.fromTo(
      selector,
      {
        [direction]: distance,
      },
      {
        [direction]: -distance,
        duration,
        ease: "sine.inOut",
      }
    );
    const randPos = floatTL.duration() * Math.random();

    floatTL.play(randPos);
    return floatTL;
  }

  checkMatchView() {
    if (this.props.viewState === TESTIMONY_VIEW) return true;
    return false;
  }

  getDataValueByKey(key) {
    if (this.props.selectedIndex === -1) return null;
    return this.props.data[this.props.selectedIndex][key];
  }

  componentDidUpdate() {
    console.log("state", this.props.viewState);
    if (this.checkMatchView()) {
      this.showTestimony();
    } else {
      this.hideTestimony();
    }
  }

  showTestimony() {
    if (this.scrollRef.current) this.scrollRef.current.scrollTop = 0;
    this.testimonyTL.tweenTo("curtains-opened");
  }

  hideTestimony() {
    this.testimonyTL.timeScale(1.5);
    this.testimonyTL.tweenTo("curtains-closed", {
      onComplete: () => {
        this.onTestimonyHideFinish();
        this.testimonyTL.timeScale(1);
      },
    });
  }

  imageLoaded() {}

  render() {
    return (
      <div
        className="testimony-display-container"
        style={{ pointerEvents: this.checkMatchView() ? "all" : "none" }}
      >
        <div className="testimony-container-left" boxCount={20}>
          <div className="image-2-pos pos-block">
            <div className="image-2-float pos-block">
              <GlitchEffect
                className="glitch-effect"
                duration={1600}
                disabled={this.props.glitchingDisabled}
              >
                <BGImageLoader
                  className="image-2"
                  glitch={true}
                  src={this.getDataValueByKey("image2")}
                  loadedCallback={this.imageLoaded.bind(this)}
                />
              </GlitchEffect>
            </div>
          </div>
          <div className="image-1-pos pos-block">
            <div className="image-1-float pos-block">
              <GlitchEffect
                className="glitch-effect"
                duration={1500}
                disabled={this.props.glitchingDisabled}
              >
                <BGImageLoader
                  className="image-1"
                  glitch={true}
                  src={this.getDataValueByKey("image1")}
                  loadedCallback={this.imageLoaded.bind(this)}
                />
              </GlitchEffect>
            </div>
          </div>
          <div className="profile-pos pos-block">
            <div className="profile-float pos-block">
              <GlitchEffect
                className="glitch-effect"
                duration={2000}
                disabled={this.props.glitchingDisabled}
              >
                <BGImageLoader
                  className="profileImage"
                  glitch={true}
                  src={this.getDataValueByKey("profileImage")}
                  loadedCallback={this.imageLoaded.bind(this)}
                />
              </GlitchEffect>
            </div>
          </div>
        </div>
        <div className="testimony-container-right">
          <div className="upper-container">
            <h2
              className="testimony-name"
              dangerouslySetInnerHTML={{
                __html: this.getDataValueByKey("coverTitle"),
              }}
            />

            <p className="country-time">
              {this.getDataValueByKey("location")},{" "}
              {this.getDataValueByKey("year")}
            </p>
            {this.getDataValueByKey("flagID") && (
              <img
                alt={this.getDataValueByKey("flagID")}
                src={`https://flagcdn.com/${this.getDataValueByKey(
                  "flagID"
                )}.svg`}
                className="country-flag"
              />
            )}
            {this.getDataValueByKey("flagSecondaryURL") &&
              this.getDataValueByKey("flagSecondaryURL") && (
                <img
                  alt={this.getDataValueByKey("flagID")}
                  src={this.getDataValueByKey("flagSecondaryURL")}
                  className="country-flag"
                />
              )}
          </div>

          <div className="lower-container" ref={this.scrollRef}>
            <span
              style={{
                display: "block",
                boxSizing: "content-box",
                overflow: "visible",
                paddingBottom: 50,
              }}
              dangerouslySetInnerHTML={{
                __html: this.getDataValueByKey("story"),
              }}
            ></span>
          </div>

          <div
            className="exit-button noSelect"
            onClick={() => this.selectedBack()}
          >
            <img
              src={CloseBtn}
              className="close-img"
              alt="Close"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        {/* <h2 className="covertitle">{this.getDataValueByKey("coverTitle")}</h2> */}
      </div>
    );
  }
}

export default TesimonyView;
