import React from 'react';
import {LanguageConsumer} from '../../providers/LanguageProvider';
import { Fade } from "react-awesome-reveal";
import imgLogo from '../../assets/images/logo.png';
import imgLogoFr from '../../assets/images/logo-fr.png';

import FacebookIcon from '../../assets/svg/FacebookIcon/FacebookIcon';
import TwitterIcon from '../../assets/svg/TwitterIcon/TwitterIcon';
import InstagramIcon from '../../assets/svg/InstagramIcon/InstagramIcon';

import './style.scss';


  const locationUrl = document.location.href;
  const urlEn = 'thecoloursofpride.com';
  const urlFr = 'couleursdelafierte.com';

  const urlEnStg = 'staging.thecoloursofpride.com';
  const urlFrStg = 'staging.couleursdelafierte.com';

  var enLink = '/';
  var frLink = '/fr';
  
  if((locationUrl.toString().includes(urlFrStg) || locationUrl.toString().includes(urlEnStg))) {
    enLink = 'https://'+urlEnStg;
    frLink = 'https://'+urlFrStg;
  } else if((locationUrl.toString().includes(urlFr) || locationUrl.toString().includes(urlEn)))   {
    enLink = 'https://'+urlEn;
    frLink = 'https://'+urlFr;
  }
//  if((locationUrl.toString().includes(urlFr) || locationUrl.toString().includes(urlEn))) {
//     enLink = 'https://'+urlEn;
//     frLink = 'https://'+urlFr;
//   }
  


class Navbar extends React.Component {

  render() {

    return (
      <LanguageConsumer>
        {(context) => (
         
          <div className="nav-wrapper">
          <Fade triggerOnce direction="down" delay={200}>
            <nav>
              <span>
                {this.props.lang === 'en' ? 
                <> 
                  <p>International<br />Day Against<br />Homophobia and <br />Transphobia</p>
                  <p className="date">May 17</p>
                </>
                : 
                <>
                  <p>JOURNÉE INTERNATIONALE<br />CONTRE L’HOMOPHOBIE<br />ET LA TRANSPHOBIE</p>
                  <p className="date">17 MAI</p>
                </>
                }

                <a href="#"></a>
              </span>

              {this.props.lang === 'en' ? 
                <> 
                <a href={enLink} className="logo-link">
                <img src={imgLogo} alt="Colours of Pride" />
              </a>
                </>
                : 
                <>
                <a href={frLink} className="logo-link">
                <img src={imgLogoFr} alt="Les Coleurs de la Fierté" />
              </a>
                </>
                }
              
              <span>
                <a href="#"></a>
                {this.props.lang === 'en' ? 
                <> 
                  <p><a href={frLink}>FR</a></p>
                </>
                : 
                <>
                  <p><a href={enLink}>EN</a></p>
                </>
                }
               
                <div className="social-wrapper">
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/InternationalMay17"><FacebookIcon iconColor="#fff"/></a>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/may17mai"><TwitterIcon iconColor="#fff" /></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/may17mai/"><InstagramIcon iconColor="#fff" /></a>
                </div>
              </span>
            </nav>
          </Fade>
          </div>
         
        )}
      </LanguageConsumer>
    )
  }
}
export default Navbar;