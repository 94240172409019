import React from 'react';

import './style.scss';

import video from '../../assets/video/pridevideo1.mp4';
import videoPoster from '../../assets/images/video-backup.jpg';

class VideoBg extends React.Component {

  render() {
    return (
      <>
     
        <div className="video-bg">
          <video className="top-video" playsInline loop autoPlay muted poster={videoPoster}>
            <source src={video} type="video/mp4" />
          </video>
        </div>

        <div className="video-overlay"></div>
      
        <div className="callout-inner">
  
        </div>
      </>
    );
  }
}

export default VideoBg;