import React from "react";
import ReactGA from 'react-ga';
import "./style.scss";
import Meta from '../../components/Meta/Meta';
import { Fade } from 'react-awesome-reveal';
import {TranslatableText, LanguageContext} from '../../providers/LanguageProvider';
import Wrapper from "../../templates/Wrapper/Wrapper";
import ContainerContent from "../../templates/ContainerContent/ContainerContent";
import ModalVideo from 'react-modal-video';
import VideoBg from '../../components/VideoBg/VideoBg';
import Testimonies from '../../components/Testimonies/Testimonies';
import Form from '../../components/Form/Form';
import SectionActions from '../../components/SectionActions/SectionActions';
import FormTestimonials from "../../components/FormTestimonials/FormTestimonials";


class Home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)

  }

  openModal () {
    this.setState({isOpen: true})
  }

  componentDidMount() {
    this.video = document.querySelector('.top-video');

    if(this.context.language=== 'en') {
      ReactGA.initialize('UA-100773913-17',{
        debug: false,
      });
      } else {
      ReactGA.initialize('UA-100773913-18',{
        debug: false,
      });
    }    
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    const isModalOpen = this.state.isOpen;
    if(isModalOpen) {
      this.video.pause();
      //prevent scroll
      document.body.style.overflow = "hidden";
    } else {
      this.video.play();
      document.body.style.overflow = "visible";
    }
  }
  static contextType = LanguageContext;

  render() {
    //set lang
    var videoID;
    if(this.context.language === 'en') {
      document.documentElement.setAttribute('lang','en');
      videoID = '548502243';
    } else {
      document.documentElement.setAttribute('lang','fr');
      videoID = '548504301';
    }

    return (
      <Wrapper pageCurrent="home" lang={this.context.language}>
      <Meta lang={this.context.language} />

      <Fade triggerOnce delay={400}>
        <section className="video-wrapper">
          <button className="video-button" onClick={this.openModal}></button>
         
          <VideoBg />
        </section>
      </Fade>
      <div className="video-modal-container">
        <ModalVideo channel='vimeo' autoplay='true' isOpen={this.state.isOpen} videoId={videoID} onClose={() => this.setState({isOpen: false})} />
      </div>
      <ContainerContent>
        <Fade triggerOnce direction="left" delay={400}>
          <h1>
            <span class="glitch" data-text="For some, showing">
              <TranslatableText
                dictionary={{
                  en: "For some, showing",
                }}
              ></TranslatableText>
            </span>
            <span class="glitch" data-text="Plusieurs portent">
              <TranslatableText
                dictionary={{
                  fr: "Plusieurs portent",
                }}
              ></TranslatableText>
            </span>
            <br />
            <span class="glitch2" data-text="their colours,">
            <TranslatableText
              dictionary={{
                en: "their colours,",
             
              }}
            ></TranslatableText>
            </span>
            <span class="glitch2" data-text="leurs couleurs">
            <TranslatableText
              dictionary={{
                fr: "leurs couleurs",
              }}
            ></TranslatableText>
            </span>
            <br />
            <span class="glitch3" data-text="isn't a choice.">
            <TranslatableText
              dictionary={{
                en: "isn't a choice.",
              }}
            ></TranslatableText>
            </span>
            <span class="glitch3" data-text="sans le vouloir.">
            <TranslatableText
              dictionary={{
                fr: "sans le vouloir.",
              }}
            ></TranslatableText>
            </span>
          </h1>
        </Fade>
        <Fade triggerOnce direction="up" delay={600}>
          <p className="sub-caption">
            <TranslatableText
                dictionary={{
                  en: "Around the world, LGBTQ+ individuals",
                  fr: "Partout dans le monde, les personnes LGBTQ+",
                }}
            ></TranslatableText>
            <br />
            <TranslatableText
                dictionary={{
                  en: "are still the victims of violence.",
                  fr: "sont encore aujourd’hui victimes de violence.",
                }}
            ></TranslatableText>
            <br /><br />
            <span class="read-stories">
              <TranslatableText
                  dictionary={{
                    en: "Read their stories.",
                    fr: "Lire leurs histoires.",
                  }}
              ></TranslatableText>
            </span>
          </p>
        </Fade>
        <section className="bruises">
        <Testimonies lang={this.context.language} />
        </section>
      </ContainerContent>
      
      <section className="form-testimonials">
        <ContainerContent>
          <div className="form-container">
            <h2>
            <TranslatableText
                dictionary={{
                  en: "Make",
                  fr: "Faites",
                }}
            ></TranslatableText>
              <br />
              <TranslatableText
                dictionary={{
                  en: "your voice",
                  fr: "vous",
                }}
            ></TranslatableText>
              <br />
              <TranslatableText
                dictionary={{
                  en: "heard.",
                  fr: "entendre.",
                }}
            ></TranslatableText>
            </h2>

            <Form lang={this.context.language} />
            
          </div>

          <FormTestimonials lang={this.context.language} />

        </ContainerContent>
      </section>

      <ContainerContent>
        <SectionActions />
      </ContainerContent>
      </Wrapper>
    );
  }
}
export default Home;
