import React from 'react';
import { Helmet } from 'react-helmet';

const MetaEn = <Helmet>
                    <title>Colours of Pride</title>
                    <meta name="description"               content="Reimagining the Pride Flag with wounds endured in the fight for LGBTQ+ inclusion" />
                    <meta property="og:url"                content="http://thecoloursofpride.com" />
                    <meta property="og:type"               content="website" />
                    <meta property="og:title"              content="Colours of Pride" />
                    <meta property="og:description"        content="Reimagining the Pride Flag with wounds endured in the fight for LGBTQ+ inclusion" />
                    <meta property="og:image"              content="https://thecoloursofpride.com/share/pride_fb_share.png" />
                </Helmet>;

const MetaFr = <Helmet>  
                    <title>Les couleurs de la fierté</title>
                    <meta name="description"               content="Repenser le drapeau de la Fierté avec des blessures du combat pour l’inclusion LGBTQ+" />
                    <meta property="og:url"                content="https://couleursdelafierte.com" />
                    <meta property="og:type"               content="website" />
                    <meta property="og:title"              content="Les couleurs de la fierté" />
                    <meta property="og:description"        content="Repenser le drapeau de la Fierté avec des blessures du combat pour l’inclusion LGBTQ+" />
                    <meta property="og:image"              content="https://couleursdelafierte.com/share/pride_fb_share.png" />
                </Helmet>;

const Meta = (props) => {
    let Meta;
    props.lang === 'fr' ? Meta = MetaFr : Meta = MetaEn;
    return (
        <>
          {Meta}
        </>
    )
}
export default Meta;