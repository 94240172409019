import React from 'react';
import './style.scss';

const CustomArrowLeft = () => (
  <svg version="1.1" className="arrow-left" x="0px" y="0px"
    viewBox="0 0 122.1 97.8" style={{enableBackground:"new 0 0 122.1 97.8"}}>
  <path d="M0,48.9L41.1,0H78L36.9,48.9L78,97.8H41.1L0,48.9z M85.2,97.8h36.9L81.1,48.9L122.1,0H85.2L44.1,48.9L85.2,97.8z"/>
  </svg>
)
export default CustomArrowLeft;



