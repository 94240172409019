import React from "react";

import BGImageLoader from "./BGImageLoader";

import Chevron from "../images/chevron.svg";

const fillColors = [
  "#882D30",
  "#CF784A",
  "#DDB465",
  "#8A8860",
  "#5A5154",
  "#673C4A",
];

class Sliver extends React.Component {
  constructor(props) {
    super(props);
    const { coverImage, coverTitle, coverTitleShort } = props.sliverData;
    const { selectHandler, index, loadedCallback } = props;
    this.coverImage = coverImage;
    this.coverTitle = coverTitle;
    this.coverTitleShort = coverTitleShort;
    this.selectHandler = selectHandler;
    this.loadedCallback = loadedCallback;
    this.index = index;
  }

  render() {
    return (
      <div
        className="sliver noSelect"
        onClick={(e) => this.selectHandler(this.index)}
      >
        <div className="sliver-block">
          <div className="image-hide-overflow">
            <BGImageLoader
              src={this.coverImage}
              loadedCallback={this.loadedCallback}
              solidFill={fillColors[this.index]}
            />
            <h2 className="sliver-title-mobile">
              {this.coverTitleShort} <img alt=">" src={Chevron} />
            </h2>
          </div>

          <div className="sliver-title-container">
            <h2
              className="sliver-title"
              dangerouslySetInnerHTML={{
                __html: this.coverTitle,
              }}
            />
          </div>
        </div>

        <div className="curtain" />
      </div>
    );
  }
}
export default Sliver;
