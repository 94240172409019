import React from 'react';
import Slider from "react-slick";
import {TranslatableText} from '../../providers/LanguageProvider';
import CustomArrowLeftSvg from '../../components/FormTestimonials/CustomArrowLeft/CustomArrowLeft';
import CustomArrowRightSvg from '../../components/FormTestimonials/CustomArrowRight/CustomArrowRight';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';


function CustomArrowRight(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
    <CustomArrowRightSvg />
    </div>
  
  );
}

function CustomArrowLeft(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
    <CustomArrowLeftSvg />
    </div>
  
  );
}


export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrowRight />,
    prevArrow: <CustomArrowLeft />
  };
  return (
    <div className="testimonials">
      <Slider {...settings}>
      <div>
        <p className="testimonial">
          <TranslatableText
            dictionary={{
              en: "When I was 13, I was rejected by students in my class because I’d just admitted that I was attracted to girls. In the years following my coming out, I was insulted and mocked. Because of that, I dropped out of school at 17. I became depressed and didn’t have the strength to report the bullying I faced. At 18, I decided to stop hiding and finally found the strength to tell my parents that I was a lesbian. It was then that everything changed, and I decided to report the people who bullied me.",
              fr: "Quand j’avais 13 ans, je me suis faite rejetée par des élèves de ma classe, étant donné que je venais d’avouer que j’étais attirée par les filles. Durant les années qui ont suivi mon coming-out, j’ai reçu des insultes et des moqueries. Cela a eu pour conséquence le fait que j’arrête l’école à 17 ans. Je suis tombée en dépression et je n’ai jamais eu la force de dénoncer l’intimidation que j’ai subie. À 18 ans, j’ai pris la décision d’arrêter de me cacher et j’ai enfin trouvé la force de dire à mes parents que j’étais lesbienne. C’est à ce moment précis que tout à changer et que j’ai choisi de dénoncer les personnes qui m’avaient intimidée."
            }}
          ></TranslatableText>
        </p>
        <h3>
        <TranslatableText
            dictionary={{
              en: "Karina - Quebec, Canada",
              fr: "Karina - Québec, Canada"
            }}
          ></TranslatableText>
        </h3>
        </div>
        <div>
        <p className="testimonial">
          <TranslatableText
            dictionary={{
              en: "I’m Dean, I’m intersex and I’ve been an activist since the Sex Garage raid in the’90s (it was a key moment for the Queer activism movement in Montreal). I’m trying to keep fighting... I’m still alive at 61... And I’ll keep fighting until my last breath to be able to live in a society that’s fair and just for everyone... Which isn’t the case today... Thousands of my friends died from HIV and wanton violence against our community. I have a transphobic landlord who physically attacked me. My neighbour had to call the police after seeing him through the window brutally attacking me with a bat... I already have major health problems and I’ve also submitted a complaint for police brutality, which has reached the Bureau desEnquêtes Indépendantes as we speak...",
              fr: "Je m’appelle Dean, je suis intersexe et militant depuis la descente du Sex Garage dans les années '90 (c’était un événement phare du mouvement militant Queer montréalais). J'essaye de continuer à me battre...je suis encore en vie à 61 ans...et j'me battrais jusqu'à ma mort afin d'être capable de vivre dans une société juste etéquitable pour toustes...ce qui n'est pas le cas aujourd'hui...Des milliers de mesamis sont décédés des suites de l'infection au V.I.H. et des violences gratuites contre notre communauté. J'ai un propriétaire transphobe qui m'a physiquement agressé. Mon voisin a dû appeler la police après l’avoir vu de sa fenêtre m'attaquer sauvagement à coups de bâton...J’ai déjà de gros problèmes de santé et j'ai aussi déposé une plainte pour brutalité policière qui est rendue au Bureau des Enquêtes indépendantes au moment où l'on se parle..."
            }}
          ></TranslatableText>
        </p>
        <h3>
        <TranslatableText
            dictionary={{
              en: "Dean - Quebec, Canada",
              fr: "Dean - Québec, Canada"
            }}
          ></TranslatableText>
        </h3>
        </div>
        <div>
        <p className="testimonial">
          <TranslatableText
            dictionary={{
              en: "When I was a teenager I was assaulted by three boys on my way home from school. They beat me a lot and told me that I shouldn't live because I was gay. I was ashamed and I was scared because I didn't yet know what it was to be gay, just to be gay, for the society I lived in, it was a problem. So I didn't tell my family. Growing up, there was no LGBTQ + role model, either in my family or at school. I had to wait until college and go to therapy to realize that it was okay to be gay! That's why today, as a teacher, I tell my students that I'm gay and that's okay. If you embrace your own differences and are comfortable with them, it's easier to understand how you can help others. Being openly who you are and being proud of it is a militant act.",
              fr: "Quand j’étais adolescent je me suis fait agressé par 3 garçons en revenant de l’école. Ils m’ont beaucoup battu et m’ont dit que je ne devrais pas vivre parce que j’étais gai. J’avais honte et j’avais peur parce que je ne savais encore ce que c’était qu’être gai, juste qu’être gai, pour la société où je vivais, c’était un problème. Alors je n’ai rien dit à ma famille. En grandissant, il n’y avait aucun modèle de personnes LGBTQ+, que ce soit dans ma famille ou à l’école. J’ai dû attendre l’Université et faire de la thérapie pour comprendre que c’était normal d’être gai ! C’est pour ça qu’aujourd’hui, en tant qu’enseignant, je dis à mes élèves que je suis gai et que c’est normal. Si vous assumez vos propres différences et que vous êtes bien avec, c’est plus facile de comprendre comment vous pouvez aider les autres. Être ouvertement qui on est et en être fier c’est un acte militant."
            }}
          ></TranslatableText>
        </p>
        <h3>
        <TranslatableText
            dictionary={{
              en: "Tiago - Brazil",
              fr: "Tiago - Brésil."
            }}
          ></TranslatableText>
        </h3>
        </div>
        <div>
        <p className="testimonial">
        <TranslatableText
            dictionary={{
              en: "In Burundi I experienced a lot of violence, verbal violence, physical violence, I was even put in prison because of my activism. When I came out as a trans woman, my dad kicked me out of the house because he was ashamed. I thought to myself, it's not right that a parent can kick their child out of the home just for that. And then I heard a lot of stories like mine about people who had been kicked out of their families, discriminated against or put in jail. It's true that it can be scary to be an activist in a country that criminalizes homosexuality, but what other option did I have? Just roll with it? Stay in my corner and shut up? Out of the question! If we don't don't act, no one else is going to do it for us. Personally, I didn't really have a choice, I had to do something to change the situation.",
              fr: "Au Burundi j’ai vécu beaucoup de violences, de la violence verbale, de la violence physique, jusqu’à être mise en prison parce que je suis militante. Quand j’ai fait mon coming-out en tant que femme trans, mon père m’a chassé de chez moi, parce qu’il avait honte. Je me suis dit, ce n’est pas normal qu’un parent puisse chasser son enfant du foyer juste pour ça. Et puis j’ai entendu beaucoup d’histoires comme la mienne de personnes qui avaient été chassées de leur famille, discriminées ou mises en prison. C’est vrai que ça peut faire peur de militer dans un pays qui criminalise l’homosexualité, mais quelle autre option est-ce que j’avais ? Me laisser faire ? Rester dans mon coin et me taire ? Hors de question ! Si nous ne faisons pas quelque chose, il n’y a personne d’autre qui va le faire pour nous. Personnellement je n’avais pas vraiment le choix, il fallait que je fasse quelque chose pour que la situation change."
            }}
          ></TranslatableText>
        </p>
        <h3>
        <TranslatableText
            dictionary={{
              en: "Mary - Burundi",
              fr: "Mary - Burundi"
            }}
          ></TranslatableText>
        </h3>
        </div>
        <div>
        <p className="testimonial"> 
        <TranslatableText
            dictionary={{
              en: "For the International Day Against Homophobia and Transphobia, I would like to tell all of my loves, that the key is to be resilient. Fear only causes fear. Don't be afraid of anything. Be certain that you are doing the right thing. Love and don't be afraid to forgive, but never forget. Never forget what they did to us, but don't be afraid to forgive, for you can choose what to fill your heart with. Make your heart as clear as a summer sky. Filled with beauty. Look for beauty at all times. Never let them break you, always keep your head up. They want to see you fold. They even want you to end your life. Don't let them do this to you. Keep moving forward, keep manifesting, keep fighting and being so beautiful and gorgeous, and ignore them. Raise the message against transphobia and homophobia loudly. One day the world will be ours!",
              fr: "Pour la Journée internationale contre l’homophobie et la transphobie, je voudrais dire à tous mes amours que la clé, c’est de faire preuve de résilience. La peur entraîne seulement la peur. N’ayez peur de rien. Soyez certains et certaines que vous faites ce qui est juste. Aimez et n’ayez pas peur de pardonner, mais n’oubliez jamais. N’oubliez jamais ce qu’ils nous ont fait, mais n’ayez pas peur de pardonner, car vous pouvez choisir de quoi vous remplissez votre cœur. Faites que votre cœur soit aussi clair qu’un ciel d’été. Rempli de beauté. Recherchez la beauté à tout moment. Ne les laissez jamais vous briser, gardez toujours la tête haute. Ils veulent vous voir plier. Ils veulent même que vous mettiez fin à vos jours. Ne leur permettez pas de vous faire ça. Continuez d’avancer, continuez de manifester, continuez de vous battre et d’être si belles, beaux et magnifiques, et ignorez-les. Portez haut le message contre la transphobie et l’homophobie. Un jour, le monde sera à nous!"
            }}
          ></TranslatableText>
        </p>
        <h3>
        <TranslatableText
            dictionary={{
              en: "Iman - Egypt",
              fr: "Iman - Égypte"
            }}
          ></TranslatableText>
        </h3>

        </div>
      </Slider>
    </div>
  );
}
// class FormTestimonials extends React.Component {

//   render() {
//     return (
//       <>
//       <div className="testimonials">
//             <p className="testimonial">consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium </p>
//           </div>
//       </>
//     );
//   }
// }

// export default FormTestimonials;