import React from 'react';
import '../../scss/partials/_vars.scss';
import './style.scss';

const InstagramIcon = (props) => (
  <>
  <svg className="instagram-icon" fill={props.iconColor} xmlns="http://www.w3.org/2000/svg" viewBox="5 5 40 40" width="50px" height="50px">
    <path d="M25,2C12.3,2,2,12.3,2,25s10.3,23,23,23s23-10.3,23-23S37.7,2,25,2z M19.1,9.1h11.8c5.5,0,10,4.5,10,10v11.8
	c0,5.5-4.5,10-10,10H19.1c-5.5,0-10-4.5-10-10V19.1C9.1,13.6,13.6,9.1,19.1,9.1z M19.1,12.6c-3.6,0-6.5,2.9-6.5,6.5v11.8
	c0,3.6,2.9,6.5,6.5,6.5h11.8c3.6,0,6.5-2.9,6.5-6.5V19.1c0-3.6-2.9-6.5-6.5-6.5H19.1z M33.3,15.6c0.7,0,1.2,0.5,1.2,1.2
	s-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2S32.6,15.6,33.3,15.6z M25,16.2c4.9,0,8.8,4,8.8,8.8s-4,8.8-8.8,8.8s-8.8-4-8.8-8.8
	S20.1,16.2,25,16.2z M25,19.7c-2.9,0-5.3,2.4-5.3,5.3s2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3S27.9,19.7,25,19.7z"/>
  </svg>
  </>
)
export default InstagramIcon;