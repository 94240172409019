import MelaniaBruise from "../images/melania-bruise.jpg";
import MelaniaClose from "../images/melania-close.jpg";
import MelaniaProfile from "../images/melania-profile.jpg";

import WilfredBruise from "../images/wilfred-bruise.jpg";
import WilfredClose from "../images/wilfred-close.jpg";
import WilfredProfile from "../images/wilfred-profile.jpg";

import PichBruise from "../images/pich-bruise.jpg";
import PichClose from "../images/pich-close.jpg";
import PichProfile from "../images/pich-profile.jpg";

import LynnBruise from "../images/lynn-bruise.jpg";
import LynnClose from "../images/lynn-close.jpg";
import LynnProfile from "../images/lynn-profile.jpg";

import MarkBruise from "../images/mark-bruise.jpg";
import MarkClose from "../images/mark-close.jpg";
import MarkProfile from "../images/mark-profile.jpg";

import PorshiaBruise from "../images/porshia-bruise.jpg";
import PorshiaClose from "../images/porshia-close.jpg";
import PorshiaProfile from "../images/porshia-profile.jpg";

export const Melania = {
  bruise: MelaniaBruise,
  profile: MelaniaProfile,
  close: MelaniaClose,
};

export const Wilfred = {
  bruise: WilfredBruise,
  profile: WilfredProfile,
  close: WilfredClose,
};

export const Pich = {
  bruise: PichBruise,
  profile: PichProfile,
  close: PichClose,
};

export const Lynn = {
  bruise: LynnBruise,
  profile: LynnProfile,
  close: LynnClose,
};
export const Porshia = {
  bruise: PorshiaBruise,
  close: PorshiaClose,
  profile: PorshiaProfile,
};
export const Mark = {
  bruise: MarkBruise,
  profile: MarkProfile,
  close: MarkClose,
};
