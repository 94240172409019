import React from 'react';
import {TranslatableText, LanguageContext} from '../../providers/LanguageProvider';

import './style.scss';


const post = async (data) => {
  const { url } = data;

  delete data.url;

  console.log(data);
  
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };

  const response = await fetch(url, params);

  if (response.status < 200 && response.status >= 300) {
    const res = await response.json();

    throw new Error(res);
  }

  return response.json();
};

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      submitted: false,
      name:'',
      email:'',
      country:'',
      message:'',
      nameError:false,
      emailError:false,
      countryError:false,
      messageError:false,
      fields: { name: '', email: '', country: '', message: ''}
    }

    this.handleSubmit    = this.handleSubmit.bind(this);
    this.handleChange    = this.handleChange.bind(this);
    this.validateInput   = this.validateInput.bind(this);
  }




  handleSubmit(event) {
    event.preventDefault();
    this.validateInput(event);
  }

  handleChange(event){
    //console.log('e ',e.currentTarget.name);
    let name = event.currentTarget.name;
    let value = event.currentTarget.value;
    this.setState({
        [name]: value
    })
}


  isValidName() {
    let {name} = this.state;
    const namePattern = /^[a-zàâçéèêëîïôûùüÿñæœ\s\.-]+$/i;
    if( name.match(namePattern) ) {
     // console.log("valid name");
      this.setState({nameError:false});
      return true;
    } else {
    //  console.log("invalid name");
      this.setState((prevState) => {
        return {
          nameError:true
        }
      });
      return false;
    }
  }

  isValidEmail(){
    let {email} = this.state;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
    if( email.match(emailPattern) ){
      //console.log('valid email')
      this.setState({ emailError: false });
      return true;
    }else{
      //console.log('invalid email')
      this.setState((prevState) => {
        return {
          emailError: true
        }
      })
      return false;
    }
  }

  isValidCountry() {
    let {country} = this.state;
    const namePattern = /^[a-zàâçéèêëîïôûùüÿñæœ\s\.-]+$/i;
    if( country.match(namePattern) ) {
     // console.log("valid country");
      this.setState({countryError:false});
      return true;
    } else {
    //  console.log("invalid country");
      this.setState((prevState) => {
        return {
          countryError:true
        }
      });
      return false;
    }
  }

  isValidMessage() {
    let {name} = this.state;
    const namePattern = /^[a-zàâçéèêëîïôûùüÿñæœ\s\.-]+$/i;
    if( name.match(namePattern) ) {
    //  console.log("valid message");
      this.setState({messageError:false});
      return true;
    } else {
      console.log("invalid message");
      this.setState((prevState) => {
        return {
          messageError:true
        }
      });
      return false;
    }
  }

  validateInput(event) {
    //console.log(event);
    let validName = this.isValidName();
    let validCountry = this.isValidCountry();
    let validEmail = this.isValidEmail();
    //let validMessage = this.isValidMessage();

    if(!validName || !validEmail || !validCountry) {
      return
    }
    this.submitForm(event);
  }

  
  submitForm(event) {
    event.preventDefault();
    const formElement = event.target;
    const {name, email, country, message} = formElement.elements;
    // build the request payload which includes the url of the end-point we want to hit
    const payload = {
      //url: 'https://stevelam.ca:8000/api/contact',
      //url:'https://pbhqwbms2i.execute-api.us-east-1.amazonaws.com/default/TheColoursOfPrideLambdaMailer',
      url:'https://14dxjgl44b.execute-api.us-east-1.amazonaws.com/Public',
      name: name.value,
      email: email.value,
      country: country.value,
      message: message.value
    };
    
    // call the post helper function which returns a promise, which we can use to update the
    // state of our component once returned
    post(payload)
      .then(() => {
        // on success, clear any errors and set submitted state to true
        this.setState({error: null, submitted: true});
      })
      .catch(error => {
        // on error, update error state with the error message and set submitted to false
        this.setState({error: error.message, submitted: false});
      });
  }

  static contextType = LanguageContext;

  render() {
    return (
      <>
      <div>
        { !this.state.submitted &&
        <form ref={this.formElement}
          onSubmit={(event) => this.handleSubmit(event)}>

          <label htmlFor="name">
            <TranslatableText
              dictionary={{
                en: "Name (or Nickname)",
                fr: "Nom (Ou pseudo)",
              }}
            ></TranslatableText>
          </label>
        
          {this.props.lang === 'en' ? 
          <>
            <input type="text" 
              minLength="2"
              onChange={this.handleChange}
              required
              placeholder="Name (or Nickname)"
              name="name"
              id="name" 
            />
          </>
          :
          <> 
            <input type="text" 
              minLength="2"
              onChange={this.handleChange}
              required
              placeholder="Nom (Ou pseudo)"
              name="name"
              id="name" 
            />
          </>
          }
          {this.state.nameError && <div className="name error">
            <TranslatableText
              dictionary={{
                en: "Please enter your name",
                fr: "Veuillez entrer votre nom",
              }}
            ></TranslatableText>
            </div>}



            <label htmlFor="email">
            <TranslatableText
              dictionary={{
                en: "EMAIL ADDRESS",
                fr: "ADRESSE COURRIEL",
              }}
            ></TranslatableText>
          </label>
        
          {this.props.lang === 'en' ? 
          <>
            <input type="email" 
              minLength="4"
              onChange={this.handleChange}
              required
              placeholder="Email Address"
              name="email"
              id="email" 
            />
          </>
          :
          <> 
            <input type="email" 
              minLength="4"
              onChange={this.handleChange}
              required
              placeholder="ADRESSE COURRIEL"
              name="email"
              id="email" 
            />
          </>
          }
          {this.state.emailError && <div className="email error">
            <TranslatableText
              dictionary={{
                en: "PLEASE ENTER YOUR EMAIL ADDRESS",
                fr: "VEUILLEZ INSCRIRE VOTRE ADRESSE COURRIEL",
              }}
            ></TranslatableText>
            </div>}


          <label htmlFor="country">
            <TranslatableText
              dictionary={{
                en: "Country",
                fr: "PAYS D’ORIGINE",
              }}
            ></TranslatableText>
          </label>

          {this.props.lang === 'en' ? 
            <>
            <input type="text" 
              onChange={this.handleChange}
              required
              placeholder="Country"
              name="country"
              id="country" />
            </>
            :
            <> 
            <input type="text" 
              onChange={this.handleChange}
              required
              placeholder="PAYS D’ORIGINE"
              name="country"
              id="country" />
            </>
          }
          {this.state.countryError && <div className="country error">
            <TranslatableText
              dictionary={{
                en: "Please enter a country",
                fr: "VEUILLEZ ENTRER VOTRE PAYS D’ORIGINE",
              }}
            ></TranslatableText>
            </div>}
            
          <label htmlFor="message">
            <TranslatableText
              dictionary={{
                en: "Tell your story",
                fr: "RACONTEZ VOTRE HISTOIRE",
              }}
            ></TranslatableText>
          </label>

          {this.props.lang === 'en' ? 
            <>
            <textarea 
              placeholder="Tell Your Story"
              onChange={this.handleChange}
              required
              minLength="10"
              name="message"
              id="message"
            />
            </>
            :
            <> 
            <textarea 
              placeholder="RACONTEZ VOTRE HISTOIRE"
              onChange={this.handleChange}
              required
              minLength="10"
              name="message"
              id="message"
            />
            </>
          }

          <button type="submit">
          <TranslatableText
            dictionary={{
              en: "Share your testimony",
              fr: "RACONTEZ VOTRE HISTOIRE",
            }}
          ></TranslatableText>
          </button>
        </form>
        }

      </div>
      { this.state.submitted && <div className="submitted">
        <TranslatableText
            dictionary={{
              en: "YOUR TESTIMONY HAS BEEN SHARED.",
              fr: "VOTRE TÉMOIGNAGE A ÉTÉ PARTAGÉ.",
            }}
        ></TranslatableText>
        <br />
        <TranslatableText
            dictionary={{
              en: "THANK YOU.",
              fr: "MERCI.",
            }}
        ></TranslatableText>
      </div>
      }
      </>
    );
  }
}

export default Form;